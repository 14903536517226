<template>
  <el-dialog
    append-to-body
    :title="title"
    ref="dialog"
    width="500px"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :visible.sync="show"
    :beforeClose="beforeClose"
  >
    <el-form ref="form" :model="form.data" label-width="100px" :rules="form.rules">
      <el-form-item label="所属机构" prop="org_id">
        <el-cascader
          v-model="form.data.org_id"
          :options="OrgTree"
          :props="{ checkStrictly: true ,label:'name',value:'id',emitPath:false}"
          placeholder="请选择上级机构"
          clearable
          size="small"
          :showAllLevels="false"
          :disabled="type==='update'"
        >
          <template slot-scope="{  data }">
            <span>{{ data.name }}</span>
          </template>
        </el-cascader>
      </el-form-item>
      <el-form-item label="角色" prop="role_id">
        <el-cascader
          v-model="form.data.role_id"
          :options="roleTree"
          :props="{ checkStrictly: true ,label:'name',value:'id',emitPath:false}"
          placeholder="请选择要绑定的角色"
          clearable
          collapse-tags
          size="small"
          :show-all-levels="false"
        >
          <template slot-scope="{  data }">
            <span>{{ data.name }}</span>
          </template>
        </el-cascader>
      </el-form-item>

      <el-tooltip class="item" effect="dark" content="初始密码默认为 123456" placement="bottom">
        <el-form-item label="账号" prop="username">
          <el-input
            v-model="form.data.username"
            placeholder="请输入账号"
            size="small"
            clearable
            maxlength="20"
            :disabled="type==='update'"
          ></el-input>
        </el-form-item>
      </el-tooltip>
      <el-form-item label="是否启用" prop="status">
        <el-switch
          v-model="form.data.status"
          active-color="#13ce66"
          inactive-color="#ff4949"
          :active-value="1"
          :inactive-value="2"
        ></el-switch>
      </el-form-item>

      <div class="submit">
        <el-button type="primary" size="small" @click="submit" :loading="form.loading">确定</el-button>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import Account from '@/api/setting/account';
import Public from '@/api/public';
export default {
  name: '',
  props: {
    title: String,
    type: String,
    OrgTree: Array,
  },
  components: {},
  data() {
    return {
      show: false,
      form: {
        data: {
          username: '',
          role_id: '', //角色绑定角色
          status: 1,
          org_id: 0,
        },
        loading: false,
        rules: {
          username: [{ required: true, message: '请输入用户名', trigger: 'change' }],
          status: [{ required: true, message: '请选择是否启用', trigger: 'change' }],
          role_id: [{ required: true, message: '请选择角色', trigger: 'change' }],
          org_id: [{ required: true, message: '请选择上级机构', trigger: 'change' }],
        },
      },
      RoleRuleTree: [],
      roleTree: [],
    };
  },
  methods: {
    beforeClose() {
      this.reset();
      this.toggle(false);
    },
    getTree() {
      if (this.form.data.org_id) {
        Public.components({
          RoleTree: this.form.data.org_id,
        }).then((res) => {
          this.roleTree = res.RoleTree;
        });
      }
    },
    change(data) {
      console.log(data);
    },
    toggle(show, org_id, data) {
      this.show = show;
      this.form.data.org_id = org_id;
      if (show && data) {
        if (this.type === 'update') {
          let { role_id, status, id } = data;
          org_id = data.org_id;
          let { username } = data.user;
          this.form.data = {
            org_id,
            role_id,
            status,
            username,
            id,
          };
        } else {
          this.form.data = data;
        }
        this.$nextTick(() => {
          this.$refs.form.resetFields();
        });
      }
      if (!show) {
        this.$emit('changeShow', false);
      }
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let form = JSON.parse(JSON.stringify(this.form.data));
          this.form.loading = true;
          Account[this.type](form)
            .then(() => {
              this.$message.closeAll();
              this.$message.success(`${this.title}成功`);
              this.toggle(false);
              this.$emit('getList');
            })
            .finally(() => {
              this.form.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    reset() {
      this.$refs.form.resetFields();
    },
    // 获取对应的规则列表
  },
  mounted() {},
  created() {
    this.getTree();
  },
  computed: {},
  watch: {
    'form.data.org_id': function (n) {
      if (n) {
        return this.getTree(n);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.submit {
  display: flex;
  justify-content: flex-end;
}
.el-cascader {
  width: 100%;
}
.el-select {
  width: 100%;
}
.info {
  padding-left: 108px;
  color: #aaa;
  padding-top: -10;
}
</style>
