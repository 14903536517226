// 身份
import { tongke } from '@/axios'
class Account {
  /**
   * @functionName list
   * @param { Object } params
   * @param { Number } params.page
   * @param { Number } params.size
   * @param { Number } params.org_id  组织id
   * @Description 身份列表
   * @return { Object }
   */
  list(params) {
    return tongke.get('/l/account', { params })
  }
  /**
   * @functionName  create
   * @param { Object }  params
   * @param { Number }  params.org_id  身份绑定组织，不传或传0绑定本单位
   * @param { String }  params.username 身份绑定用户名
   * @param { Number }  params.role_id 身份绑定角色
   * @param { Number }  params.status 状态，1-启用|2禁用
   * @Description 创建身份
   * @return { Object }
   */
  create(params) {
    return tongke.post('/l/account', params)
  }
  /**
   * @functionName  update
   * @param { Object }  params
   * @param { Number }  params.id  身份ID
   * @param { Number }  params.org_id  身份绑定角色
   * @param { Number }  params.status 状态，1-启用|2禁用
   * @Description 更新身份
   * @return { Object }
   */
  update(params) {
    return tongke.put(`/l/account/${params.id}`, params)
  }
  remove(id) {
    return tongke.delete(`/l/account/${id}`)
  }
}

export default new Account()
